import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { getGlobalData } from "../../../common/utils/data";
import { FormFactor, MediaQuery, PriceUnitType } from "../../../common/enums";
import { Divider } from "../../../common/components/Divider";
import {
  Heading1,
  Heading3,
  Heading5,
  Body1,
  Body2,
} from "../../../common/components/Text";

import { BottomArea } from "./BottomArea";

const data = getGlobalData();

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding: 64px 0;

  @media ${MediaQuery[FormFactor.Mobile]} {
    flex-direction: column;
    gap: 5vw;
    padding-top: 32px;
  }
`;

const TextWrapper = styled.div`
  padding-right: 20vw;

  @media ${MediaQuery[FormFactor.Tablet]} {
    padding-right: 5vw;
  }

  @media ${MediaQuery[FormFactor.Mobile]} {
    padding-right: 0;
  }
`;

const CalculatorArea = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  gap: 8px;

  height: fit-content;

  box-shadow: rgb(0 0 0 / 35%) -2px 4px 20px -4px;

  max-width: 500px;

  padding: 48px;

  @media ${MediaQuery[FormFactor.Mobile]} {
    margin-top: 0;
  }
`;

const CalculatorTopBottomArea = styled.div``;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const RadioButton = styled.input`
  margin: 0;
`;

const InputFiledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media ${MediaQuery[FormFactor.Mobile]} {
    width: 100%;
  }
`;

const InputField = styled.input`
  &,
  &:hover,
  &:focus,
  &:focus-visible {
    border-radius: 8px;
    border-width: 1px;
    border-color: #00000091;

    outline: 0;
    padding: 16px;
  }
`;

const SelectInput = styled.select`
  border-radius: 8px;
  border-width: 1px;
  border-color: #00000091;

  outline: 0;
  padding: 16px;

  cursor: pointer;

  background-color: transparent;
`;

const setValue = (value, setter) => {
  if (value === "") {
    setter("");

    return;
  }

  const adjustedValue = value?.replace(/,/g, ".");

  if (adjustedValue?.match(new RegExp("\\.", "g"))?.length > 1) {
    return;
  }

  const parsedNumericValue = parseFloat(adjustedValue);

  if (!parsedNumericValue && parsedNumericValue !== 0) {
    return;
  }

  setter(value);
};

const getTitleForPriceUnitType = (priceUnitType) => {
  if (priceUnitType === PriceUnitType.Weight) {
    return "Varans vikt (gram)";
  }

  if (priceUnitType === PriceUnitType.Volume) {
    return "Varans volym (liter)";
  }

  return "Antal i förpackningen (styck)";
};

const PLACEHOLDER = {
  key: "placeholder",
  displayName: "Varan jag kollar upp priset på är...",
};

const FOOD_ITEMS = [
  PLACEHOLDER,
  ...(data?.map((foodItem) => foodItem?.metadata) ?? []),
];

export const Calculate = () => {
  const [price, setPrice] = useState("");
  const [priceUnitType, setPriceUnitType] = useState(PriceUnitType.Weight);
  const [priceUnitValue, setPriceUnitValue] = useState("");
  const [selectedFoodItemKey, setSelectedFoodItemKey] = useState();

  const handlePriceUnitTypeChange = useCallback((e) => {
    setPriceUnitType(e.target.value);
  }, []);

  const handlePriceChange = useCallback((e) => {
    setValue(e.target.value, setPrice);
  }, []);

  const handleWeightChange = useCallback((e) => {
    setValue(e.target.value, setPriceUnitValue);
  }, []);

  const handleFoodItemChange = useCallback((e) => {
    setSelectedFoodItemKey(e.target.value);
  }, []);

  return (
    <ContentWrapper>
      <TextWrapper>
        <Heading1 style={{ marginTop: 0 }}>
          Bli mer prismedveten med matsvepet!
        </Heading1>

        <Body1>
          Det är svårt att veta hur bra matpriserna i butiken verkligen är om
          man inte har någonting att jämföra med.
          <br />
          <br />
          Knappa in varans pris samt vikt, volym eller storlek och se hur
          jämförelsepriset för varan står sig i dagsläget.
        </Body1>
      </TextWrapper>

      <CalculatorArea>
        <CalculatorTopBottomArea>
          <Heading3 style={{ margin: 0 }}>Räkna ut jämförelsepriset</Heading3>
        </CalculatorTopBottomArea>

        <Divider />

        <InputArea>
          <InputGroup>
            <Heading5 style={{ margin: "0 0 8px 0" }}>
              Typ av jämförelsepris
            </Heading5>

            <div>
              <RadioButtonWrapper>
                <RadioButton
                  type="radio"
                  name="options" // Use the same name for radio buttons in a group
                  value={PriceUnitType.Weight}
                  checked={priceUnitType === PriceUnitType.Weight}
                  onChange={handlePriceUnitTypeChange}
                />
                <Body1>Kilopris</Body1>
              </RadioButtonWrapper>
              <Body2>Används för t.ex nötfärs, smör & kyckling</Body2>
            </div>

            <div>
              <RadioButtonWrapper>
                <RadioButton
                  type="radio"
                  name="options" // Use the same name for radio buttons in a group
                  value={PriceUnitType.Volume}
                  checked={priceUnitType === PriceUnitType.Volume}
                  onChange={handlePriceUnitTypeChange}
                />
                <Body1>Literpris </Body1>
              </RadioButtonWrapper>
              <Body2>Används för t.ex nötfärs, smör & kyckling</Body2>
            </div>

            <div>
              <RadioButtonWrapper>
                <RadioButton
                  type="radio"
                  name="options" // Use the same name for radio buttons in a group
                  value={PriceUnitType.Unit}
                  checked={priceUnitType === PriceUnitType.Unit}
                  onChange={handlePriceUnitTypeChange}
                />
                <Body1>Styckpris</Body1>
              </RadioButtonWrapper>
              <Body2>Används för t.ex , t.ex ägg, cirtron & paprika</Body2>
            </div>
          </InputGroup>

          <div>
            <Heading5 style={{ margin: "0 0 8px 0" }}>Värden</Heading5>

            <InputGroup>
              <InputFiledWrapper>
                <Body1>Varans pris (kr)</Body1>

                <InputField
                  type="text"
                  inputmode="numeric"
                  value={price}
                  onChange={handlePriceChange}
                />
                {/* <div>Varans pris(debug): {price}</div> */}
              </InputFiledWrapper>

              <InputFiledWrapper>
                <Body1>{getTitleForPriceUnitType(priceUnitType)}</Body1>
                <InputField
                  type="text"
                  inputmode="numeric"
                  value={priceUnitValue}
                  onChange={handleWeightChange}
                />
                {/* <div>Varans vikt(debug): {weight}</div> */}
              </InputFiledWrapper>
            </InputGroup>
          </div>

          <div>
            <Heading5 style={{ margin: "0 0 8px 0" }}>Typ av vara</Heading5>

            <InputFiledWrapper>
              <Body1>
                Välj för att se prisjämförelse för varan med varor i andra
                butiker i nästa steg (frivilligt)
              </Body1>

              <SelectInput
                value={selectedFoodItemKey}
                defaultValue={null}
                onChange={handleFoodItemChange}
              >
                {FOOD_ITEMS.map((foodItem) => {
                  const { key, displayName } = foodItem;

                  return (
                    <option key={key} value={key}>
                      {displayName}
                    </option>
                  );
                })}
              </SelectInput>
            </InputFiledWrapper>
          </div>
        </InputArea>

        <Divider />

        <CalculatorTopBottomArea>
          <BottomArea
            price={price}
            priceUnitType={priceUnitType}
            priceUnitValue={priceUnitValue}
            selectedFoodItemKey={selectedFoodItemKey}
          />
        </CalculatorTopBottomArea>
      </CalculatorArea>
    </ContentWrapper>
  );
};
