import React from "react";
import styled from "styled-components";
import { Flex } from "antd";

import { FormFactor, MediaQuery } from "../../common/enums";
import { Heading2, Body1 } from "../../common/components/Text";
import { Page } from "../../common/components/Page";
import { Button } from "../../common/components/Button";
import { Link } from "../../common/components/Link";
// import { Newsfeed } from "../../common/components/Newsfeed";

import { Explanation } from "./Explanation";

const Hero = styled.div`
  display: flex;
  flex-direction: column;

  padding-right: 50vw;
  margin: 80px 0;

  @media ${MediaQuery[FormFactor.Tablet]} {
    padding-right: 25vw;
    margin: 40px 0;
  }

  @media ${MediaQuery[FormFactor.Mobile]} {
    padding-right: 25vw;
    margin: 40px 0;
  }
`;

export const Home = () => {
  return (
    <Page>
      <Hero>
        <Heading2>Börja Matsvepa!</Heading2>

        <Body1 style={{ marginBottom: "32px" }}>
          Hitta du de bästa extrapriserna i dina butiker och det aktuella
          jämförelsepriset på massvis av matvaror hos oss!
          <br />
          <br />
          Med vår jämförelsepris-kalkylator kan du även snabbt få du koll på hur
          dyr din vara egentligen är.
        </Body1>

        <Flex gap="8px">
          <Link to="/kalkylatorn">
            <Button>Kalkylatorn</Button>
          </Link>

          <Link to="/alla-produkter">
            <Button>Alla varor</Button>
          </Link>
        </Flex>
      </Hero>

      <Explanation />

      {/* <Newsfeed /> */}
    </Page>
  );
};
