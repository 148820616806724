import { useState, useCallback, useEffect } from "react";

const useScroll = () => {
  const [scrollY, setScrollY] = useState();

  const listenToScroll = useCallback(() => {
    const _scrollY =
      document.body.scrollTop || document.documentElement.scrollTop;

    setScrollY(_scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [listenToScroll]);

  return { scrollY };
};

export default useScroll;
