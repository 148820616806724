import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 32px;
  padding: 24px;

  cursor: pointer;

  transition: box-shadow 0.3s ease-in-out;

  box-shadow: rgb(0 0 0 / 15%) -2px 4px 20px -4px;

  &:hover {
    box-shadow: rgb(0 0 0 / 35%) -2px 4px 20px -4px;
  }

  ${({ isShowingMore }) =>
    isShowingMore && "box-shadow: rgb(0 0 0 / 15%) -2px 4px 20px -4px;"};
`;

export const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-bottom: 16px;

  div {
    h1 {
      margin: 0;
    }
  }
`;
