export const Environment = {
  Workstation: "workstation",
};

// TODO: Move away from form factor to small, medium, large...
export const FormFactor = {
  Mobile: "mobile",
  Tablet: "tablet",
  Desktop: "desktop",
};

export const MediaQuery = {
  [FormFactor.Mobile]: "(max-width: 600px)",
  [FormFactor.Tablet]: "(min-width: 601px) and (max-width: 1000px)",
  [FormFactor.Desktop]: 1,
};

export const FoodStore = {
  Ica: "ica",
  Hemkop: "hemkop",
  Willys: "willys",
  // Coop: "coop",
};

export const PriceUnitType = {
  Weight: "weight",
  Volume: "volume",
  Unit: "unit",
};

export const PriceUnitTypeDisplayName = {
  [PriceUnitType.Weight]: "kr/kg",
  [PriceUnitType.Volume]: "kr/l",
  [PriceUnitType.Unit]: "kr/st",
};
