import React from "react";

import { Heading1, Heading3, Body1, Body2 } from "../../Text";
import { OfferItemList } from "../../ItemList";

import { Card, PriceSection } from "./ProductCard.styled";
import { useProductCard } from "./useProductCard";

export const ProductCard = ({
  item,
  storeOffersForItem,
  showMoreForItem,
  onClickShowMoreOrLess,
}) => {
  const {
    handleClick,
    isShowingMore,
    // shouldShowDiscountMin,
    // discountMin,
    nonDiscountMedian,
  } = useProductCard(item, showMoreForItem, onClickShowMoreOrLess);

  const { displayName, priceSuffix } = item?.metadata || {};

  const foundOffers = !!storeOffersForItem?.length;

  return (
    <Card isShowingMore={isShowingMore} onClick={handleClick}>
      <Heading3>{displayName}</Heading3>

      <PriceSection>
        <div>
          <Body1>Kostar just nu vid fullpris runt</Body1>
          <Heading1>{`${nonDiscountMedian} ${priceSuffix}`}</Heading1>
        </div>

        {foundOffers && (
          <div>
            <Body1 fontWeight="bold" style={{ color: "red" }}>
              {isShowingMore
                ? "Här är extrapriserna i dina butiker"
                : "Vi hittade extrapriser i dina valda butik(er)!"}
            </Body1>
          </div>
        )}

        {/* {shouldShowDiscountMin && (
          <div>
            <Body1>Det billigaste extrapriset vi kunde hitta just nu är</Body1>
            <Heading1>{`${discountMin} ${priceSuffix}`}</Heading1>{" "}
          </div>
        )} */}
      </PriceSection>

      {isShowingMore && (
        <div>
          {foundOffers && <OfferItemList offerItems={storeOffersForItem} />}

          {/* <Body1
            fontWeight="bold"
            style={{ marginTop: "24px", marginBottom: "8px" }}
          >
            Förklaring
          </Body1>

          <Body2>
            Jämförelsepriset i stora bokstäver ovan är medianen av fullpriset på
            priset för varan i alla de butiker vi har jämfört.
            <br />
            I denna jämförelse ingår både butikernas egna lågprismärken samt de
            lite dyrare märkesvarorna.
            <br />
            <br />
            Detta betyder att om du kan handla {displayName},<br />
            med lägre jämförelsepris än {`${nonDiscountMedian} ${priceSuffix}`},
            <br />
            så handlar du den varan billigare än genomsnittet i Sverige.
          </Body2> */}
        </div>
      )}

      {foundOffers && (
        <Body2
          style={{ marginTop: isShowingMore ? "16px" : 0 }}
          fontWeight="bold"
        >
          {isShowingMore ? "Dölj extrapriser" : "Visa extrapriser"}
        </Body2>
      )}
    </Card>
  );
};
