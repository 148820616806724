import React from "react";
import styled from "styled-components";
import { Select as SelectBase } from "antd";

import { getStores } from "../../common/utils/data";

const Select = styled(SelectBase)`
  width: 100%;
  margin-top: 24px;
`;

const stores = getStores();

const options = stores
  ?.map((store) => {
    return { label: store?.name, value: store?.id };
  })
  .sort((a, b) =>
    a.label.localeCompare(b.label, "sv", { sensitivity: "base" })
  );

const filterOption = (input, option) => {
  const words = input.split(/\s+/);

  return words?.every((word) => {
    return (option?.label ?? "").toLowerCase().includes(word.toLowerCase());
  });
};

export const StoreSelector = ({ onChange }) => {
  return (
    <Select
      mode="multiple"
      placeholder="Sök efter dina butiker..."
      // defaultValue={["a", "c"]}
      options={options}
      filterOption={filterOption}
      onChange={onChange}
    />
  );
};
