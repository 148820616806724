import React, { useState } from "react";

import { Hamburger } from "./Hamburger";
import { Sider } from "./Sider";

export const Menu = () => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const handleToggleHamburger = () => {
    setSideMenuOpen((prev) => !prev);
  };

  return (
    <>
      <Hamburger active={sideMenuOpen} onClick={handleToggleHamburger} />
      <Sider visible={sideMenuOpen} onClick={handleToggleHamburger} />
    </>
  );
};
