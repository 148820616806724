import React from "react";

import { Page } from "../../common/components/Page";

import { Calculate } from "./Calculate";

export const ComparePriceCalculator = () => {
  return (
    <Page>
      <Calculate />
    </Page>
  );
};
