import styled from "styled-components";

export const Image = styled.div`
  height: ${({ height = "100%" }) => height};
  width: ${({ width = "100%" }) => width};
  max-width: 160px;
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio};`};
  flex: none;

  background: url(${({ url }) => url}) no-repeat;
  background-position: ${({ backgroundPosition = "center center" }) =>
    backgroundPosition};
  background-size: ${({ backgroundSize = "cover" }) => backgroundSize};
`;
