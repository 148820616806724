import React from "react";
import { NavLink } from "react-router-dom";

import { Page } from "../../common/components/Page";
import {
  Heading1,
  Heading2,
  Heading4,
  Body1,
} from "../../common/components/Text";
import {
  TextContent,
  TextContentSection,
} from "../../common/components/ContentWrapper";

export const WhatIsComparePrice = () => {
  return (
    <Page>
      <TextContent>
        <TextContentSection>
          <Heading1>Jämförelsepris på mat</Heading1>
          <Body1>
            Visst har du också stått framför hyllorna i mataffären och undrat
            vad som egentligen är ett bra erbjudande? Jämförelsepriset är din
            bästa vän när du letar efter de bästa priserna på matvaror. Det
            fungerar som din personliga guide i djungeln av prisskyltar. Vi ska
            ta en närmare titt på vad jämförelsepriset egentligen är och hur det
            kan hjälpa dig att spara pengar medan du handlar din favoritmat.
          </Body1>
        </TextContentSection>

        <TextContentSection>
          <Heading2>Vad är jämförelsepriset på en matvara?</Heading2>
          <Body1>
            Jämförelsepriset är en standardiserad enhet för prissättning som
            används i Sverige för att underlätta jämförelser mellan olika
            matvaror. Det är ett värde som anger priset per kilogram, liter
            eller styck för en specifik produkt. Genom att konvertera priset på
            en produkt till samma enhet blir det lättare för konsumenter att
            avgöra vilken produkt som ger det mest ekonomiska värdet.
            <br />
            <br />I praktiken innebär det att du kan jämföra priset på två olika
            varor, även om de är förpackade i olika storlekar eller enheter, och
            snabbt bedöma vilken som ger dig mest produkt för dina pengar.
            Jämförelsepriset är ett användbart verktyg för att fatta välgrundade
            beslut när du handlar matvaror och hjälper dig att maximera dina
            chanser att handla din mat till det bästa priset!
          </Body1>
        </TextContentSection>

        <TextContentSection>
          <Heading2>Hur räknar man ut jämförelsepriset?</Heading2>
          <Body1>
            Vill du snabbt kolla upp jämförelsepriset på en produkt utan att
            behöva räkna själv? Testa{" "}
            <NavLink to="/kalkylatorn">
              kalkylatorn för jämförelsepriset
            </NavLink>
            {"!"}
          </Body1>
          <Heading4>Kilopris</Heading4>
          <Body1>
            Om du vill jämföra priset på två olika produkter som säljs i
            viktenhet kilogram, så är beräkningen enkel. Ta priset på produkten
            och dela det med vikten i kilogram. Till exempel, om en påse med
            potatis kostar 20 kronor och väger 2 kilogram, blir jämförelsepriset
            20 / 2 = 10 kronor per kilogram.
          </Body1>
          <Heading4>Literpris</Heading4>
          <Body1>
            För flytande produkter som mjölk eller olja som säljs i liter,
            använder du samma princip. Dela priset på produkten med antalet
            liter i förpackningen. Säg att en flaska juice kostar 15 kronor och
            innehåller 1,5 liter. Jämförelsepriset blir då 15 / 1,5 = 10 kronor
            per liter.
          </Body1>
          <Heading4>Styckpris</Heading4>
          <Body1>
            Vissa produkter, som toalettpapper eller tvålpaket, säljs per styck.
            För att räkna ut jämförelsepriset för dessa, dividera priset med
            antalet enheter. Om en förpackning innehåller 6 rullar toalettpapper
            och kostar 30 kronor, blir jämförelsepriset 30 / 6 = 5 kronor per
            rulle.
          </Body1>
        </TextContentSection>
      </TextContent>
    </Page>
  );
};
