import React from "react";
import { NavLink } from "react-router-dom";

import { Heading1, Body1 } from "../../common/components/Text";
import { Page } from "../../common/components/Page";

export const NotFound = () => {
  return (
    <Page>
      <Heading1>Hoppsan!</Heading1>
      <Body1>
        Det verkar som att du försökt nå en sida om inte finns.
        <br />
        <br />
        <NavLink to="/">Gå tillbaka till startsidan</NavLink>
      </Body1>
    </Page>
  );
};
