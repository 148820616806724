import styled, { css } from "styled-components";

const BaseFont = css`
  font-family: "Montserrat", sans-serif;
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
`;

export const Heading1 = styled.h1`
  font-weight: 900;
  font-size: 40px;
  ${BaseFont};
`;

export const Heading2 = styled.h2`
  font-weight: 700;
  font-size: 32px;
  ${BaseFont};
`;

export const Heading3 = styled.h3`
  font-weight: 700;
  font-size: 28px;
  ${BaseFont};
`;

export const Heading4 = styled.h4`
  font-weight: 700;
  font-size: 24px;
  ${BaseFont};
`;

export const Heading5 = styled.h5`
  font-weight: 700;
  font-size: 20px;
  ${BaseFont};
`;

export const Body1 = styled.div`
  font-weight: 300;
  font-size: 16px;
  ${BaseFont};
`;

export const Body2 = styled.div`
  font-weight: 300;
  font-size: 12px;
  ${BaseFont};
`;
