import { useCallback } from "react";

import { sortArrayOfObjectsByProperty } from "../../../utils/arrays";

/**
 * Formats a numeric value by converting it to a string representation.
 * If the value is an integer, it returns the value as a string.
 * If the value is a decimal, it returns the value rounded to 2 decimal places.
 *
 * @param {number} value - The numeric value to be formatted.
 * @returns {string} The formatted value as a string.
 */
const formatValue = (value) => {
  if (!(value % 1)) {
    return value?.toString();
  }

  return value?.toFixed(2);
};

/**
 * Determines whether to show the discount for a product based on the minimum price per price unit of discounted items and the median price per price unit of non-discounted items.
 * @param {number} discountedItemsPricePerPriceunitMin - The minimum price per price unit of discounted items.
 * @param {number} nonDiscountedItemsPricePerPriceunitMedian - The median price per price unit of non-discounted items.
 * @returns {boolean} - True if the discount should be shown, false otherwise.
 */
const showDiscountMin = (
  discountedItemsPricePerPriceunitMin,
  nonDiscountedItemsPricePerPriceunitMedian
) => {
  if (!discountedItemsPricePerPriceunitMin) {
    return false;
  }

  return (
    discountedItemsPricePerPriceunitMin <
    nonDiscountedItemsPricePerPriceunitMedian
  );
};

export const useProductCard = (
  item,
  showMoreForItem,
  onClickShowMoreOrLess
) => {
  const {
    metadata,
    discountedItems,
    nonDiscountedItems,
    nonDiscountedItemsPricePerPriceunitMedian,
    discountedItemsPricePerPriceunitMin,
  } = item || {};
  const { key } = metadata || {};

  const isShowingMore = showMoreForItem === key;

  const shouldShowDiscountMin = showDiscountMin(
    discountedItemsPricePerPriceunitMin,
    nonDiscountedItemsPricePerPriceunitMedian
  );

  const discountMin = formatValue(discountedItemsPricePerPriceunitMin);

  const nonDiscountMedian = formatValue(
    nonDiscountedItemsPricePerPriceunitMedian
  );

  const allItems = sortArrayOfObjectsByProperty(
    [...(discountedItems ?? []), ...(nonDiscountedItems ?? [])],
    "pricePerPriceunitWithDiscount"
  );

  const handleClick = useCallback(() => {
    onClickShowMoreOrLess(key);
  }, [key, onClickShowMoreOrLess]);

  return {
    handleClick,
    isShowingMore,
    shouldShowDiscountMin,
    discountMin,
    nonDiscountMedian,
    allItems,
  };
};
