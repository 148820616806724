import React from "react";
import styled from "styled-components";

import { useFormFactor } from "../../common/hooks/useFormFactor";
import { FormFactor } from "../../common/enums";
import { Image } from "../../common/components/Image";
import { Heading2, Body1 } from "../../common/components/Text";
import How1Png from "../../assets/how/shopping_01.png";
import How2Png from "../../assets/how/shopping_02.png";
import How3Png from "../../assets/how/shopping_03.png";

const COMMON_IMAGE_PROPS = {
  style: { aspectRatio: 2168 / 1819, maxWidth: "none" },
  height: "auto",
  width: "auto",
  backgroundSize: "contain",
  backgroundPosition: "bottom",
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubCardWrapper = styled.div`
  display: flex;
  flex-direction: ${({ formFactor }) =>
    formFactor === FormFactor.Mobile ? "column" : "row"};

  gap: ${({ formFactor }) =>
    formFactor === FormFactor.Mobile ? "12vw" : "2vw"};

  padding: ${({ formFactor }) =>
    formFactor === FormFactor.Mobile ? "10vw" : "1vw 0"};
`;

const SubCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2vw;
`;

const ImageText = styled(Body1)`
  text-align: center;
  padding: 0 10%;
`;

export const Explanation = () => {
  const currentFormFactor = useFormFactor();

  return (
    <Card>
      <Heading2 style={{ marginBottom: "8px" }}>
        Hitta mataffärens bästa deals med matsvepet!
      </Heading2>

      <SubCardWrapper formFactor={currentFormFactor}>
        <SubCard>
          <Image {...COMMON_IMAGE_PROPS} url={How1Png} />
          <ImageText>
            Det är lätt att bli överväldigad av matbutikernas prismärkning
          </ImageText>
        </SubCard>
        <SubCard>
          <Image {...COMMON_IMAGE_PROPS} url={How2Png} />
          <ImageText>
            Med hjälp av matsvepet.se kan du äntligen någonting att jämföra
            butikens pris mot varans genomsnittliga priset i landet
          </ImageText>
        </SubCard>
        <SubCard>
          <Image {...COMMON_IMAGE_PROPS} url={How3Png} />
          <ImageText>
            Lämna butiken med vetskapen att du handlat dina varor till ett bra
            pris!
          </ImageText>
        </SubCard>
      </SubCardWrapper>
    </Card>
  );
};
