import React from "react";
import styled from "styled-components";

import { Page } from "../../common/components/Page";
import { Heading1, Body1 } from "../../common/components/Text";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding-top: 48px;
`;

export const Cookies = () => {
  return (
    <Page>
      <Content>
        <div>
          <Heading1 style={{ marginBottom: "16px" }}>Cookie policy</Heading1>
          <Body1>
            Cookie-policy för matsvepet.se
            <br />
            <br />
            matsvepet.se använder cookies för att förbättra din
            användarupplevelse och för att samla in information om hur vår
            webbplats används. Vi använder dessa cookies för att analysera
            trafiken på vår webbplats och för att optimera prestanda och
            funktionalitet.
            <br />
            <br />
            Vad är cookies?
            <br />
            <br />
            Cookies är små datafiler som lagras på din dator eller mobiltelefon
            när du besöker en webbplats. De används för att samla in information
            om hur du använder webbplatsen och för att anpassa din upplevelse på
            webbplatsen.
            <br />
            <br />
            Google Analytics 4
            <br />
            <br />
            Vi använder Google Analytics 4 för att samla in information om hur
            vår webbplats används. Google Analytics 4 använder cookies för att
            samla in information om hur du använder vår webbplats, inklusive din
            IP-adress, tidigare besök på vår webbplats och vilka sidor du
            besöker. Denna information används för att analysera användningen av
            vår webbplats och för att optimera prestanda och funktionalitet.
            <br />
            <br />
            Vi använder följande cookies från Google Analytics 4:
            <br />
            <br />
            _ga: Används för att skilja användare åt. Lagringstid: 2 år. _gid:
            Används för att skilja användare åt. Lagringstid: 24 timmar. _gat:
            Används för att minska antalet förfrågningar till Google Analytics
            4. Lagringstid: 1 minut. Hur kan du kontrollera cookies?
            <br />
            <br />
            Du kan ställa in din webbläsare för att blockera cookies eller för
            att varna dig när en webbplats försöker lägga till cookies på din
            dator eller mobiltelefon. Du kan också ta bort cookies som redan har
            lagrats på din dator eller mobiltelefon. Var dock medveten om att om
            du blockerar eller tar bort cookies kan detta påverka
            funktionaliteten och användarupplevelsen på vår webbplats.
            <br />
            <br />
            Samtycke
            <br />
            <br />
            Genom att använda vår webbplats samtycker du till vår användning av
            cookies enligt denna cookie-policy. Om du inte samtycker till vår
            användning av cookies kan du ställa in din webbläsare för att
            blockera cookies eller avstå från att använda vår webbplats.
            <br />
            <br />
            Kontakta oss
            <br />
            <br />
            Om du har några frågor om vår cookie-policy eller vår användning av
            cookies kan du kontakta oss genom att skicka ett DM på instagram.
          </Body1>
        </div>
      </Content>
    </Page>
  );
};
