import styled from "styled-components";

import { Body1 } from "../Text";
import { Item, OfferItem } from "./Item";

const ItemListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ItemList = ({ items }) => {
  return (
    <>
      <Body1 fontWeight="bold" style={{ marginBottom: "8px" }}>
        Alla produkter vi hittade
      </Body1>

      <ItemListWrapper>
        {items?.map((item) => {
          return <Item item={item} />;
        })}
      </ItemListWrapper>
    </>
  );
};

export const OfferItemList = ({ offerItems }) => {
  return (
    <ItemListWrapper>
      {offerItems?.map((offerItem) => {
        const { storeName, offers } = offerItem ?? {};

        return offers?.map((offer) => {
          return <OfferItem offer={offer} storeName={storeName} />;
        });
      })}
    </ItemListWrapper>
  );
};
