import styled from "styled-components";
import { FormFactor, MediaQuery } from "../../enums";

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 64px;
  padding-top: 48px;

  @media ${MediaQuery[FormFactor.Mobile]} {
    gap: 32px;
    padding-top: 24px;
  }
`;

export const TextContentSection = styled.div`
  padding: 0 20vw;

  @media ${MediaQuery[FormFactor.Mobile]} {
    padding: 0 5vw;
  }
`;

export const TextContentSection2 = styled.div`
  padding: 0 20vw 0 0;

  @media ${MediaQuery[FormFactor.Mobile]} {
    padding: 0 5vw 0 0;
  }
`;
