import React, { useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { NavLink } from "react-router-dom";

import { Heading2 } from "../Text";

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // semi-transparent black overlay
  z-index: 18; // ensure that the overlay is above the page
`;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${({ visible }) => (visible ? "0%" : "-101%")});
  ${({ visible }) =>
    visible && "box-shadow: -2px 4px 30px -4px rgba(0, 0, 0, 0.75);"};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  a {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.2rem;
    text-decoration: none;
  }
`;

const LinkText = styled(Heading2)`
  color: #000000;

  transition: all 0.2s ease-in-out;

  &:hover {
    color: #000000aa;
  }
`;

export const Sider = ({ visible, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(false);
  }, [onClick]);

  return (
    <>
      {visible && (
        <>
          <GlobalStyle />
          <ModalOverlay onClick={handleClick} />
        </>
      )}

      <StyledMenu visible={visible}>
        <NavLink to="/" onClick={handleClick}>
          <LinkText>Hem</LinkText>
        </NavLink>

        <NavLink to="/alla-produkter" onClick={handleClick}>
          <LinkText>Alla produkter</LinkText>
        </NavLink>

        <NavLink to="/kalkylatorn" onClick={handleClick}>
          <LinkText>Kalkylatorn</LinkText>
        </NavLink>

        <NavLink to="/vad-ar-jamforelsepris" onClick={handleClick}>
          <LinkText>Vad är jämförelsepris?</LinkText>
        </NavLink>

        <NavLink to="/om" onClick={handleClick}>
          <LinkText>Om matsvepet</LinkText>
        </NavLink>
      </StyledMenu>
    </>
  );
};
