import styled from "styled-components";

import { Body2 } from "../Text";

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const Item = ({ item }) => {
  const {
    brand,
    name,
    pricePerPriceunitWithDiscount,
    priceSuffix,
    isDiscounted,
  } = item ?? {};

  return (
    <ItemWrapper>
      <Body2>
        {brand} {name}, {pricePerPriceunitWithDiscount?.toFixed(2)}
        {priceSuffix}
      </Body2>
      <Body2 fontWeight="bold">{isDiscounted && "(extrapris)"}</Body2>
    </ItemWrapper>
  );
};

export const OfferItem = ({ offer, storeName }) => {
  const { name, pricePerPriceunitWithDiscount, priceSuffix } = offer ?? {};

  const str = `${storeName}: ${name}, ${pricePerPriceunitWithDiscount?.toFixed(
    2
  )} ${priceSuffix}`;

  return (
    <ItemWrapper>
      <Body2>{str}</Body2>
      {/* <Body2 fontWeight="bold">{isDiscounted && "(extrapris)"}</Body2> */}
    </ItemWrapper>
  );
};
