import globalData from "../../data/global/allGlobal.json";
import storesData from "../../data/stores/allStores.json";

export const getStores = () => {
  return storesData?.flatMap((store) => {
    return store?.stores?.map(({ storeId, name }) => ({ id: storeId, name }));
  });
};

export const getGlobalData = () => {
  return globalData;
};

const ALL_STORES = storesData?.flatMap(({ stores }) => stores);

export const getStoreData = async (selectedStoreIds) => {
  const stores = selectedStoreIds?.reduce((acc, selectedStoreId) => {
    const foundStore = ALL_STORES?.find(
      ({ storeId }) => storeId === selectedStoreId
    );

    if (foundStore) {
      acc.push(foundStore);
    }

    return acc;
  }, []);

  return stores;
};
