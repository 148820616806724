import React from "react";
import styled from "styled-components";

import instagramPng from "../../../assets/social/instagram-footer.png";
import { Image } from "../Image";
import { Body2 } from "../Text";
import { Link } from "../Link";

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
`;

const currentYear = new Date().getFullYear();

export const Footer = () => {
  return (
    <Wrapper>
      <a
        href="https://www.instagram.com/matsvepet/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          url={instagramPng}
          width="32px"
          height="32px"
          backgroundSize="contain"
        />
      </a>
      <Body2 style={{ color: "#ffffff" }}>
        © {currentYear} Matsvepet. Alla rättigheter förbehållna.
      </Body2>
      <Link
        style={{
          textDecoration: "underline dotted",
          color: "#ffffff",
          width: "auto",
        }}
        to="/cookies"
      >
        <Body2>Cookie policy</Body2>
      </Link>
    </Wrapper>
  );
};
