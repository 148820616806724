import React from "react";
import styled, { css } from "styled-components";

import { FormFactor, MediaQuery } from "../../enums";
import { Footer } from "./Footer";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const LayoutPage = styled.div`
  flex-grow: 1;
  padding: 80px 8vw;

  @media ${MediaQuery[FormFactor.Tablet]} {
    padding: 80px 8vw;
  }

  @media ${MediaQuery[FormFactor.Mobile]} {
    padding: 80px 4vw;
  }

  ${({ skipSidePadding }) =>
    skipSidePadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const Page = ({ skipSidePadding, children }) => {
  return (
    <PageWrapper>
      <LayoutPage skipSidePadding={skipSidePadding}>{children}</LayoutPage>
      <Footer />
    </PageWrapper>
  );
};
