export const sortArrayOfObjectsByProperty = (
  data,
  property,
  nestedProperty
) => {
  if (nestedProperty) {
    return data?.sort(
      (a, b) =>
        a?.[property]?.[nestedProperty] - b?.[property]?.[nestedProperty]
    );
  }
  return data?.sort((a, b) => a?.[property] - b?.[property]);
};

export const getMedianOfObjectsByProperty = (array, property) => {
  const medianIndex = Math.floor(array?.length / 2);

  return array?.[medianIndex]?.[property];
};

export const getMeanOfArray = (array) =>
  array?.reduce((a, b) => a + b, 0) / array?.length;
