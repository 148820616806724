import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { FormFactor, MediaQuery } from "../../common/enums";
import { Heading2, Heading4, Body1 } from "../../common/components/Text";
import { Page } from "../../common/components/Page";
import { Products } from "../../common/components/Products";
import { getStoreData } from "../../common/utils/data";

import { StoreSelector } from "./StoreSelector";

const ProductsWrapper = styled.div`
  padding: 0;

  @media ${MediaQuery[FormFactor.Desktop]} {
    padding: 0 160px;
  }
`;

export const AllProducts = () => {
  // const [, /* selectedStores, */ setSelectedStores] = useState([]);
  const [selectedStoreData, setSelectedStoreData] = useState([]);

  // TODO: Persist selected stores in local storage
  const handleStoresChange = useCallback((selectedStoreIds) => {
    // setSelectedStores(selectedStoreIds);

    getStoreData(selectedStoreIds).then((data) => {
      setSelectedStoreData(data);
    });
  }, []);

  return (
    <Page>
      <ProductsWrapper>
        <Heading2>Alla varor</Heading2>
        <Body1>
          Här hittar du det dagsaktuella genomsnittet av jämförelsepriset på
          massvis med matvaror.
          <br />
          <br />
        </Body1>

        <Body1>
          Genom att välja de affärer du kan tänka dig handla i kan du även se om
          matvarorna finns till extrapris just nu.
          <br />
          Sök efter dina favoritbutiker nedan!
        </Body1>
        <StoreSelector onChange={handleStoresChange} />

        <Heading4>Jämförelsepriset på alla varor just nu</Heading4>
        <Products searchQuery={""} selectedStoreData={selectedStoreData} />
      </ProductsWrapper>
    </Page>
  );
};
