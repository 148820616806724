import React from "react";

import { Unavailable /*  CookieConsent , */ } from "./common/components/Modal";
import { TopBar } from "./common/components/TopBar";
import { Menu } from "./common/components/Menu";
import { AppRoutes } from "./AppRoutes";

function App() {
  return (
    <>
      {/* Uncomment the <Unavailable /> component to temporarly block users from accessing the service */}
      <Unavailable />
      {/* <CookieConsent /> */}
      <TopBar />
      <Menu />
      <AppRoutes />
    </>
  );
}

export default App;
