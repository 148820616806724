import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { FormFactor, MediaQuery } from "../enums";

export const useFormFactor = () => {
  const isMobile = useMediaQuery({ query: MediaQuery[FormFactor.Mobile] });
  const isTablet = useMediaQuery({
    query: MediaQuery[FormFactor.Tablet],
  });
  // const isDesktop = useMediaQuery({
  //   query: "(min-width: 1001px)",
  // });

  const currentFormFactor = useMemo(() => {
    if (isMobile) {
      return FormFactor.Mobile;
    } else if (isTablet) {
      return FormFactor.Tablet;
    }
    return FormFactor.Desktop;
  }, [isMobile, isTablet]);

  return currentFormFactor;
};
