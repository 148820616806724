import React, { useState, useCallback } from "react";

import { getGlobalData } from "../../utils/data";

import { Divider } from "../Divider";

import { ProductCard } from "./ProductCard";

const data = getGlobalData();

export const Products = ({ searchQuery, selectedStoreData }) => {
  const [showMoreForItem, setShowMoreForItem] = useState(null);

  const handleClickShowMoreOrLess = useCallback(
    (newShowMoreForItem) => {
      if (newShowMoreForItem === showMoreForItem) {
        setShowMoreForItem(null);

        return;
      }

      setShowMoreForItem(newShowMoreForItem);
    },
    [showMoreForItem]
  );

  const filteredData = data?.filter((item) => {
    const { displayName } = item?.metadata ?? {};

    return (
      !searchQuery?.length ||
      displayName?.toLowerCase()?.includes(searchQuery?.toLowerCase()?.trim())
    );
  });

  return (
    <div id="products">
      {filteredData?.map((item, index) => {
        const isLastItem = index === filteredData?.length - 1;

        const storeOffersForItem = selectedStoreData?.reduce(
          (acc, storeData) => {
            const { name: storeName, storeId, offers } = storeData ?? {};

            const foundOffer = offers?.find(
              (offer) => offer?.key === item?.metadata?.key
            );

            if (!foundOffer) {
              return acc;
            }

            const { data, cheapestItem } = foundOffer;

            return [
              ...acc,
              {
                storeName,
                storeId,
                offers: data,
                cheapestItem,
              },
            ];
          },
          []
        );

        return (
          <div key={item?.metadata?.key}>
            <ProductCard
              item={item}
              storeOffersForItem={storeOffersForItem}
              showMoreForItem={showMoreForItem}
              onClickShowMoreOrLess={handleClickShowMoreOrLess}
            />

            {!isLastItem && <Divider />}
          </div>
        );
      })}
    </div>
  );
};
