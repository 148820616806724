import styled from "styled-components";

export const Button = styled.button`
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  font-size: 16px; /* Set font-size */
  padding: 10px 20px; /* Padding around text */
  border: none; /* Remove border */
  cursor: pointer; /* Change mouse cursor on hover */
  border-radius: 5px; /* Slightly rounded corners */

  transition: all 0.2s;

  &:hover {
    background-color: #0056b3; /* Darker blue background on hover */
  }
`;
