import React from "react";
import { Routes, Route } from "react-router-dom";

import { NotFound } from "./modules/NotFound";
import { Home } from "./modules/Home";
import { AllProducts } from "./modules/AllProducts";
import { ComparePriceCalculator } from "./modules/ComparePriceCalculator";
import { WhatIsComparePrice } from "./modules/WhatIsComparePrice";
import { About } from "./modules/About";
import { Cookies } from "./modules/Cookies";

/* Add the modules in the side menu here: app/src/common/components/Menu/Sider.js */
export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/alla-produkter" element={<AllProducts />} />
      <Route path="/kalkylatorn" element={<ComparePriceCalculator />} />
      <Route path="/vad-ar-jamforelsepris" element={<WhatIsComparePrice />} />
      <Route path="/om" element={<About />} />
      <Route path="/cookies" element={<Cookies />} />
      {/* non existing route, catch all */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
