import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import appLogo from "../../../assets/appLogos/appLogo.jpg";

import useScroll from "../../hooks/useScroll";

import { Image } from "../Image";
import { NavLink as NavLinkBase } from "react-router-dom";

const PARTIALLY_HIDE_TOP_MENU_FOR_ROUTES = [];

const TopBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  background-color: white;
  z-index: 1;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.3s ease-in-out;
  transform: translateY(${({ visible }) => (visible ? "0%" : "-101%")});
`;

const NavLink = styled(NavLinkBase)`
  height: 100%;
  width: 100%;
  max-width: 160px;
`;

export const TopBar = () => {
  const { pathname } = useLocation();
  const { scrollY } = useScroll();

  const showTopBar =
    !PARTIALLY_HIDE_TOP_MENU_FOR_ROUTES.includes(pathname) || scrollY > 550;

  return (
    <TopBarWrapper visible={showTopBar}>
      <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
        <Image url={appLogo} backgroundSize="contain" />
      </NavLink>
    </TopBarWrapper>
  );
};
