import React from "react";
import styled from "styled-components";
import appLogo from "../../../assets/appLogos/appLogo.jpg";
import instagramPng from "../../../assets/social/instagram.png";
import { Image } from "../Image";
// import { Environment } from "../../enums";
import { Body1 } from "../Text";
import { GlobalStyle, ModalOverlay, ModalWrapper } from "./styled";

const Text = styled(Body1)`
  user-select: none;
  text-align: center;
`;

export const Unavailable = () => {
  // const [numberOfLogoClicks, setNumberOfLogoClicks] = useState(0);
  // const [numberOfTextClicks, setNumberOfTextClicks] = useState(0);

  // const handleClickLogo = useCallback(() => {
  //   setNumberOfLogoClicks((n) => n + 1);
  // }, []);

  // const handleClickText = useCallback(() => {
  //   setNumberOfTextClicks((n) => n + 1);
  // }, []);

  // const isWorkstation =
  //   process.env.REACT_APP_ENVIRONMENT === Environment.Workstation;
  // const isClosedByClicks = numberOfLogoClicks === 7 && numberOfTextClicks === 6;

  // if (isWorkstation || isClosedByClicks) {
  //   return null;
  // }

  return (
    <>
      <GlobalStyle />

      <ModalOverlay>
        <ModalWrapper>
          <Image
            url={appLogo}
            width="200px"
            height="35px"
            backgroundSize="contain"
            // onClick={handleClickLogo}
          />
          <Text>
            matsvepet.se är snart tillgänglig för allmänheten! <br />
            Följ vår instagram för att hålla dig uppdaterad <br />
          </Text>
          <a
            href="https://www.instagram.com/matsvepet/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              url={instagramPng}
              width="50px"
              height="50px"
              backgroundSize="contain"
            />
          </a>
        </ModalWrapper>
      </ModalOverlay>
    </>
  );
};
