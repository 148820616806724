import React from "react";
import styled from "styled-components";
import HamburgerReact from "hamburger-react";

const HamburgerLayer = styled.div`
  z-index: 20;
  position: absolute;
  padding: 16px 24px;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
`;

export const Hamburger = ({ active, onClick }) => {
  return (
    <HamburgerLayer>
      <HamburgerReact
        toggled={active}
        toggle={onClick}
        rounded
        duration={0.7}
      />
    </HamburgerLayer>
  );
};
