import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import { isGaConsentSet } from "../../utils/cookies";
import { Heading1, Body1, Body2 } from "../Text";
import { Link } from "../Link";
import { GlobalStyle, ModalOverlay, ModalWrapper } from "./styled";

const GA_CONSENT_COOKIE = "ga_consent";
const GA_CONSENT_COOKIE_OPTIONS = { expires: 7 };

const Cookie = styled.div`
  user-select: none;
  text-align: center;
  font-size: 80px;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: rotate 25s linear infinite;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Button = styled.div`
  padding: 16px;
  color: ${({ color = "#000000bb" }) => color};
  background-color: ${({ backgroundColor = "#f3f0f0" }) => backgroundColor};
  border-radius: 16px;
  cursor: pointer;

  transition: all 0.3s;

  border: none;
  &:hover {
    background-color: ${({ backgroundColorHover = "#f3f0f044" }) =>
      backgroundColorHover};
  }
`;

export const CookieConsent = () => {
  const [hideModal, setHideModal] = useState(false);

  const { initialize } = useGoogleAnalytics();

  const handleAccept = useCallback(() => {
    Cookies.set(GA_CONSENT_COOKIE, true, GA_CONSENT_COOKIE_OPTIONS);
    initialize();
    setHideModal(true);
  }, [initialize]);

  const handleDeny = useCallback(() => {
    Cookies.set(GA_CONSENT_COOKIE, false, GA_CONSENT_COOKIE_OPTIONS);
    setHideModal(true);
  }, []);

  if (isGaConsentSet() || hideModal) {
    return null;
  }

  return (
    <>
      <GlobalStyle />

      <ModalOverlay>
        <ModalWrapper>
          <Cookie>🍪</Cookie>
          <Heading1>Kakor?</Heading1>
          <Body1 textAlign="center">
            För att lära oss hur vi kan bli bättre använder vi på matsvepet
            kakor.
            <br />
            <br />
            <Link style={{ textDecoration: "underline dotted" }} to="/cookies">
              <Body2>Läs mer om vår cookie policy här</Body2>
            </Link>
          </Body1>
          <Row>
            <Button
              color="#FFFFFF"
              backgroundColor="#54FF51"
              backgroundColorHover="#54FF5199"
              onClick={handleAccept}
            >
              Okej!
            </Button>
            <Button onClick={handleDeny}>Nej tack</Button>
          </Row>
        </ModalWrapper>
      </ModalOverlay>
    </>
  );
};
