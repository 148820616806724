import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { getGlobalData } from "../../../common/utils/data";
import { PriceUnitType, PriceUnitTypeDisplayName } from "../../../common/enums";
import {
  Heading2,
  Heading5,
  Body1,
  Body2,
} from "../../../common/components/Text";
import { sortArrayOfObjectsByProperty } from "../../../common/utils/arrays";

const data = getGlobalData();

const getComparePrice = (_price, priceUnitType, _priceUnitValue) => {
  const price = parseFloat(_price?.replace(/,/g, "."));
  const priceUnitValue = parseFloat(_priceUnitValue?.replace(/,/g, "."));

  if (!price || !priceUnitType || !priceUnitValue) {
    return null;
  }

  if (priceUnitType === PriceUnitType.Weight) {
    return ((price / priceUnitValue) * 1000)?.toFixed(2);
  }

  if (priceUnitType === PriceUnitType.Volume) {
    return (price / priceUnitValue)?.toFixed(2);
  }

  // unit
  return (price / priceUnitValue)?.toFixed(2);
};

const Wrapper = styled.div`
  cursor: pointer;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BottomArea = ({
  price,
  priceUnitType,
  priceUnitValue,
  selectedFoodItemKey,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const comparePrice = getComparePrice(price, priceUnitType, priceUnitValue);

  // Add more here in this object later if needed
  const enteredItem = {
    enteredByUserInCalculator: true,
    pricePerPriceunitWithDiscount: parseFloat(comparePrice),
  };

  const selectedItem = data?.find(
    (item) => item?.metadata?.key === selectedFoodItemKey
  );

  const { discountedItems, nonDiscountedItems } = selectedItem ?? {};

  const allItems = sortArrayOfObjectsByProperty(
    [...(discountedItems ?? []), ...(nonDiscountedItems ?? []), enteredItem],
    "pricePerPriceunitWithDiscount"
  );

  const handleClickShowDetails = useCallback(() => {
    if (!selectedItem) {
      return;
    }

    setShowDetails((prevState) => !prevState);
  }, [selectedItem]);

  return (
    <Wrapper onClick={handleClickShowDetails}>
      <Heading2 style={{ margin: 0 }}>
        {comparePrice
          ? `Varans jämförelsepris: ${comparePrice} ${PriceUnitTypeDisplayName[priceUnitType]}`
          : "Fyll i samtliga fält ovan för att räkna ut varans jämförelsepris"}
      </Heading2>

      {showDetails && (
        <>
          <Heading5 style={{ marginBottom: "8px" }}>
            Alla produkter av samma typ
          </Heading5>

          <ItemList>
            {allItems?.map((item) => {
              const {
                enteredByUserInCalculator,
                brand,
                name,
                pricePerPriceunitWithDiscount,
                isDiscounted,
              } = item ?? {};

              if (enteredByUserInCalculator) {
                return (
                  <Body1 style={{ color: "red" }}>
                    {pricePerPriceunitWithDiscount?.toFixed(2)}
                    {PriceUnitTypeDisplayName[priceUnitType]}, Din vara
                  </Body1>
                );
              }

              return (
                <Body2>
                  {pricePerPriceunitWithDiscount?.toFixed(2)}
                  {PriceUnitTypeDisplayName[priceUnitType]}, {name}, {brand}{" "}
                  {isDiscounted && "(extrapris)"}
                </Body2>
              );
            })}
          </ItemList>
        </>
      )}

      {comparePrice && !selectedItem && (
        <Body1 style={{ marginTop: "16px" }}>
          Välj vilken typ av vara du tittar på, för att se hur jämförelsepriset
          på din vara står sig mot alla andra varor av samma typ
        </Body1>
      )}

      {comparePrice && selectedItem && (
        <Body1 style={{ marginTop: "16px" }}>
          {showDetails
            ? "Dölj jämförelse med alla varor"
            : "Se jämförelse med alla varor"}
        </Body1>
      )}
    </Wrapper>
  );
};
