import React from "react";

import { Page } from "../../common/components/Page";
import { Heading1, Heading2, Body1 } from "../../common/components/Text";
import {
  TextContent,
  TextContentSection,
} from "../../common/components/ContentWrapper";

export const About = () => {
  return (
    <Page>
      <TextContent>
        <TextContentSection>
          <Heading1>Om Matsvepet</Heading1>
          <Body1>
            Matsvepet skapades för att ge dig som konsument ett verktyg för att
            kunna jämföra matpriser, blixstsnabbt och barnsligt enkelt.
          </Body1>
        </TextContentSection>

        <TextContentSection>
          <Heading2>Problemet vi vill lösa</Heading2>
          <Body1>
            Vi vill att varje konsument ska ha ett riktmärke för matpriser att
            kunna jämföra priset i sin butik mot.
            <br />
            <br />
            Genom att ha ett genomsnittligt <b>jämförelsepris</b> för enskilda
            varor är tanken att du som konsument snabbt och enkelt kan få en
            uppfattning om ifall priset i butiken är över eller under snittet
            jämfört med andra butiker.
          </Body1>
        </TextContentSection>

        <TextContentSection>
          <Heading2>Hur vi löser problemet</Heading2>
          <Body1>
            Vi läser av massvis utav matvaror från flertalet matbutiker runt om
            i landet.
            <br />
            <br />
            Sedan hittar vi den billigaste varan i varje kategori, från varje
            butik, och tittar på dess jämförelsepris.
            <br />
            <br />
            Vi kan nu räkna ut vad genomsnittet för jämförelsepriset på varorna
            är runt om i landet.
            <br />
            <br />
            På så sätt har du ett referens-pris du kan använda dig av nästa gång
            du står i butiken och funderar på ifall den vara du tänkt köpa är
            billigare eller dyrare än det genomsnittliga priset i Sverige just
            nu.
            <br />
            <br />
            Smart va?
          </Body1>
        </TextContentSection>

        <TextContentSection>
          <Heading2>Hur skiljer sig Matsvepet från andra tjänster?</Heading2>
          <Body1>
            Vi fokuserar enbart på den absolut viktigaste delen av matpriserna,
            nämligen varornas <b>jämförelsepris</b>.
            <br />
            <br />
            Jämförelsepriset är det pris som står brevid varans styckpris, och
            säger t.ex hur mycket smöret kostar per kilo, hur mycket äggen
            kostar per styck eller hur mycket mjölken kostar per liter.
            <br />
            <br />
            Det brukar t.ex se ut såhär: 100kr/kg, 5kr/st eller25kr/l.
          </Body1>
        </TextContentSection>

        <TextContentSection>
          <Heading2>
            Men varför är det viktigt att titta på jämförelsepriset?
          </Heading2>
          <Body1>
            I tider av hög inflation, så är det vanligt att fenomenet
            "krympflation" visar sig hos producenterna.
            <br />
            <br />
            Krympflation betyder att istället för att höja priset på en vara, så
            minskar producenten storleken på produkterna.
            <br />
            Detta ger skenet av att prisökningarna inte är så stora, men vad som
            i slutändan sker är att varan du köper har ett högre jämförelsepris.
          </Body1>
        </TextContentSection>
      </TextContent>
    </Page>
  );
};
